<template>
  <div class="container">
    <Box
      :title="$t('c6d02e5') + `${total ? '（' + total + '）' : ''}`"
      style="margin-bottom: 0"
    >
      <div
        v-for="item in list"
        :key="item.id"
        class="msg-box"
        @click="setRead(item)"
      >
        <div v-if="!item.readed" class="read-tag"></div>
        <div class="requirement">
          <div class="group">
            <div class="label">{{ handleLabel(item, 'title') }}：</div>
            <div class="cont">{{ item.title | textFilter }}</div>
          </div>
          <div class="group">
            <div class="label">{{ handleLabel(item, 'content') }}：</div>
            <div class="cont">{{ item.content | textFilter }}</div>
          </div>
          <div v-if="!item.flag" class="group">
            <div class="label">{{ handleLabel(item, 'contactPhone') }}：</div>
            <div class="cont">{{ item.contactPhone | textFilter }}</div>
          </div>
          <div class="more">
            <span @click="jumpDetail(item)">{{ $t('ad6ab8a') }}&gt;&gt;</span>
          </div>
        </div>

        <div v-if="item.replyList.length" class="msg-list">
          <template v-for="(reply, idx) in item.replyList">
            <div
              v-if="idx >= item.replyList.length - 3 || item._replyShow"
              :key="reply.id"
              class="item"
              :class="{ 'item-right': reply.flag }"
            >
              <div
                class="avatar"
                :style="{ backgroundImage: `url(${reply.picture})` }"
              ></div>
              <div class="cont">
                <div class="meta">
                  <!-- reply.flag ? reply.name : item.contactPhone -->
                  <!-- <span  style="margin-right: 30px">{{ -->

                  <span v-if="reply.flag" style="margin-right: 10px">{{
                    formatDate(reply.createTime)
                  }}</span>
                  <span>{{ reply.name }}</span>
                  <span v-if="!reply.flag" style="margin-left: 10px">{{
                    formatDate(reply.createTime)
                  }}</span>
                </div>
                <div class="msg">
                  {{ reply.replyContent }}
                </div>
              </div>
            </div>
          </template>
        </div>

        <div
          v-if="item.replyList.length > 3"
          class="load-more"
          :class="{ 'load-more-active': item._replyShow }"
        >
          <span @click="showMore(item)">{{
            item._replyShow ? $t('e8aa23e') : $t('8b5d6bd')
          }}</span>
        </div>

        <div v-if="item._replyOpen" class="reply">
          <el-input
            v-model="item._replyTxt"
            class="input"
            type="textarea"
            :rows="7"
            :placeholder="$t('b3771cd')"
            maxlength="500"
            show-word-limit
          />
        </div>
        <div v-if="item._replyOpen" class="actions">
          <el-button
            type="primary"
            class="btn"
            :loading="item._replying"
            @click="sendReply(item)"
          >
            {{ $t('f0030c1') }}
          </el-button>
          <el-button class="btn" @click="closeReply(item)">{{ $t('6b0feee') }}</el-button>
        </div>
        <div v-else class="actions">
          <el-button type="primary" class="btn" @click="openReply(item)">{{
            item.flag ? $t('249393c') : $t('a9435f5')
          }}</el-button>
        </div>
      </div>

      <div v-if="!list.length" class="empty">{{ $t('434f325') }}</div>

      <div class="pager">
        <el-pagination
          :current-page="query.page"
          :page-size="query.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getList"
        />
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box'
import Api from '@/api/userCenter'
import dayjs from 'dayjs'
// const labelData = {
//   REQUIREMENTS: {
//     title: '需求标题',
//     content: '需求内容',
//     contactPhone: '咨询联系电话',
//   },
//   LAW_OFFICE: {
//     title: '律所名称',
//     content: '律所介绍',
//     contactPhone: '咨询联系电话',
//   },
//   GRAB_LAW_OFFICE: {
//     title: '律所名称',
//     content: '律所介绍',
//     contactPhone: '咨询联系电话',
//   },
//   LAWYER: {
//     title: '律师姓名',
//     content: '所属律所',
//     contactPhone: '咨询联系电话',
//   },
//   GRAB_LAWYER: {
//     title: '律师姓名',
//     content: '所属律所',
//     contactPhone: '咨询联系电话',
//   },
//   SERVICE: {
//     title: '服务标题',
//     content: '服务内容',
//     contactPhone: '咨询联系电话',
//   },
// }
export default {
  components: {
    Box,
  },
  data() {
    return {
      label: {
        title: '',
        content: '',
      },
      loading: false,
      list: [],
      total: 0,
      unread: 0,
      query: {
        page: 1,
        size: 5,
      },
    }
  },
  computed: {
    labelData() {
      return {
        REQUIREMENTS: {
          title: this.$t('23f0457'),
          content: this.$t('484c370'),
          contactPhone: this.$t('bcfa30d'),
        },
        LAW_OFFICE: {
          title: this.$t('73002c0'),
          content: this.$t('0c9f905'),
          contactPhone: this.$t('bcfa30d'),
        },
        GRAB_LAW_OFFICE: {
          title: this.$t('73002c0'),
          content: this.$t('0c9f905'),
          contactPhone: this.$t('bcfa30d'),
        },
        LAWYER: {
          title: this.$t('e908cfc'),
          content: this.$t('a3c3439'),
          contactPhone: this.$t('bcfa30d'),
        },
        FOREIGN_AFFAIRS_LAWYER: {
          title: this.$t('e908cfc'),
          content: this.$t('a3c3439'),
          contactPhone: this.$t('bcfa30d'),
        },
        GRAB_LAWYER: {
          title: this.$t('e908cfc'),
          content: this.$t('a3c3439'),
          contactPhone: this.$t('bcfa30d'),
        },
        SERVICE: {
          title: this.$t('9c1b456'),
          content: this.$t('e650329'),
          contactPhone: this.$t('bcfa30d'),
        },
      }
    },
    str1() {
      return this.$t('5d09355')
    },
  },
  mounted() {
    this.getList(1)
  },
  methods: {
    handleLabel(item, key) {
      if (item && this.labelData[item.type]) {
        return this.labelData[item.type][key]
      }
    },
    formatDate(date) {
      let format = 'MM月DD日 HH:mm'

      if (!dayjs().isSame(date, 'year')) {
        format = 'YYYY年' + format
      }

      return dayjs(date).format(format)
    },
    getList(page) {
      this.loading = true
      Api.getMsgList({ ...this.query, page })
        .then((res) => {
          this.query.page = page
          this.list = res.pageData.content.map((item) => ({
            ...item,
            _replyTxt: '',
            _replyOpen: false,
            _replying: false,
            _replyShow: false,
            _setreading: false,
          }))
          this.total = res.pageData.page.total
          this.unread = res.unread
        })
        .catch((e) => {})
        .then(() => {
          this.loading = false
        })
    },
    openReply(item) {
      item._replyOpen = true
    },
    closeReply(item) {
      item._replyOpen = false
    },
    showMore(item) {
      item._replyShow = !item._replyShow
    },
    sendReply(item) {
      if (item._replying) {
        return
      }

      if (!item._replyTxt) {
        return this.$message.error(this.str1)
      }

      item._replying = true
      Api.replyMsg(item.id, { replyContent: item._replyTxt })
        .then((res) => {
          this.$message.success(res.message)
          this.getList(this.query.page)
        })
        .catch(() => {
          item._replying = false
        })
    },
    jumpDetail(item) {
      const id = item.contactId
      const type = item.type
      let url = ''

      if (type === 'REQUIREMENTS') {
        url = `/service/demand/details?id=${id}`
      } else if (type === 'SERVICE') {
        url = `/service/desk/details?id=${id}`
      } else if (type === 'LAWYER' || type === 'LAW_OFFICE') {
        url = `/service/affairs/details?id=${id}&sourceType=LAW_OFFICE&type=0`
      } else if (type === 'GRAB_LAWYER' || type === 'GRAB_LAW_OFFICE') {
        url = `/service/affairs/details?id=${id}&scId=${id}&sourceType=LAW_OFFICE&type=1`
      } else if (type === 'FOREIGN_AFFAIRS_LAWYER') {
        url = `/communication/lawyer/detail?id=${id}`
      }

      if (url) {
        this.$router.push(url)
      }
    },
    setRead(item) {
      if (!item.readed && !item._setreading) {
        item._setreading = true
        Api.setRead(item.id)
          .then((res) => {
            item.readed = true
            this.unread = Math.max(0, this.unread - 1)
          })
          .catch(() => {})
          .then(() => {
            item._setreading = false
          })
      }
    },
  },
}
</script>

<style lang="less" scoped>
// .container {
//   width: 920px;
//   margin: 30px auto;
// }

.empty {
  padding: 30px;
  color: #999999;
  font-size: 14px;
  text-align: center;
}

.pager {
  padding: 20px 20px 0;
  text-align: right;
}

.msg-box {
  position: relative;
  margin: 0 20px 20px;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  padding: 30px;
  overflow: hidden;

  .read-tag {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: url(~@/assets/usercenter/unread.png) no-repeat center;
    background-size: cover;
  }

  .requirement {
    border-bottom: 1px dashed #f3f3f3;
    padding-bottom: 20px;

    .group {
      padding-bottom: 14px;
      display: flex;
      font-size: 14px;
      line-height: 24px;
    }

    .label {
      color: #999999;
    }

    .cont {
      position: relative;
      min-width: 0;
      flex: 1;
      color: #333;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .more {
      text-align: right;
      margin-top: -14px;
      font-size: 14px;
      color: #00a4ff;
      line-height: 24px;

      span {
        cursor: pointer;
      }
    }
  }

  .load-more {
    padding-top: 30px;
    text-align: center;
    font-size: 14px;
    color: #00a4ff;
    line-height: 20px;

    span {
      cursor: pointer;

      &::after {
        content: '';
        margin-left: 8px;
        display: inline-block;
        width: 7px;
        height: 7px;
        border: 1px solid #00a4ff;
        border-left: 0;
        border-top: 0;
        transform: translate(0, -35%) rotate(45deg);
        transition: 0.3s all;
      }
    }

    &-active span::after {
      transform: translate(0, 35%) rotate(225deg);
    }
  }

  .reply {
    padding-top: 30px;

    & /deep/ textarea {
      // padding: 12px;
      border: 1px solid #eee;
      font-size: 14px;
      color: #333333;
      line-height: 1.5;
      resize: none;
    }
  }

  .actions {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    .btn {
      display: block;
      margin: 0 10px;
      padding: 0;
      width: 120px;
      height: 32px;
      border-radius: 4px;
    }
  }
}

.msg-list {
  padding-top: 5px;

  .item {
    display: flex;
    padding: 20px 0 0 0;
  }
  .avatar {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin: 0px 12px 0 0;
    border-radius: 50%;
    background: #f1f8fe no-repeat center;
    background-size: cover;
    overflow: hidden;
  }
  .cont {
    min-width: 0;
    // max-width: 630px;
    flex: 1;
  }

  .meta {
    font-size: 12px;
    color: #999999;
    padding-bottom: 6px;
  }

  .msg {
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    background: #f7f7f7;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    color: #121e2c;
    line-height: 24px;
    word-break: break-all;

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      right: 100%;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid #f7f7f7;
    }
  }

  .item-right {
    justify-content: flex-end;

    .avatar {
      order: 2;
      margin: 0 0 0 12px;
    }

    .cont {
      text-align: right;
    }

    .msg {
      text-align: left;
      background: #d4ecff;
      border: 1px solid #bde2ff;
      &::before {
        right: auto;
        left: 100%;
        border-right: none;
        border-left: 5px solid #d4ecff;
      }
    }
  }
}
</style>
